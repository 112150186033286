import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/auth.context';
import { IUser } from '../../interfaces/auth.interface';
import { Button } from '../../components/button.component';
import { Headline } from '../../components/headline.component';
import { Text } from '../../components/text.component';
import logo from '../../assets/images/logo.svg';
import Components, {
  RightColumn,
  WhiteBox,
  Form,
  ErrorWrapper,
} from './login.style';
import Input from '../../components/input.component';
import { Checkbox } from '../../components/checkbox.component';
import { StyledLink } from '../../components/link.component';
import CircleLoader from '../../components/circle-loader';

export const LoginPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const [form, setForm] = useState<IUser>({
    email: '',
    password: '',
  });
  const [error, setError] = useState<boolean>(false);
  const from = location.state?.from?.pathname || searchParams.get('returnUrl') || '/';
  const [registerLoading, setRegisterLoading] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const handleChange = (key: keyof IUser) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setForm((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setRegisterLoading(true);
    auth.signIn(
      { ...form, email: form.email.toLocaleLowerCase(), rememberMe },
      () => {
        navigate(from, { replace: true });
        setRegisterLoading(false);
      },
      () => setError(true),
    );
  };

  return (
    <Components.Wrapper>
      <RightColumn>
        <img src={logo} alt={'mark it'} />

        <div className="df">

          <WhiteBox>
            <Headline>{t('login.enter')}</Headline>
            {error && (
              <ErrorWrapper>
                <Text small>{t('login.error')}</Text>
                <StyledLink link={'/register'} text={t('register.action2')} />
              </ErrorWrapper>
            )}
            <Form onSubmit={handleSubmit}>
              <Input
                label="Email"
                type="email"
                error={error}
                value={form.email}
                onChange={handleChange('email')}
                showButtons={false}
                autoComplete="email"
              />
              <Input
                label="Пароль"
                type="password"
                error={error}
                value={form.password}
                onChange={handleChange('password')}
                showButtons={false}
                autoComplete="current-password"
              />
              <StyledLink link={'/reset-password'} text={t('recover.enter')} />
              <Checkbox onChange={() => setRememberMe(!rememberMe)} label="Запомнить меня" />

              <Button type="submit">
                {registerLoading ? <CircleLoader /> : t('login.action')}
              </Button>
            </Form>
          </WhiteBox>

          <StyledLink link={'/register'} text={t('register.action')} />
        </div>

      </RightColumn>

    </Components.Wrapper>
  );
};
